import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { Directionatta, Directionread, Directionupdate, DirectionAdd, Directiondel, DirectionList } from '@/api/ceshi_xiugai/product';
import Upload from '@/components/Upload/SingleImage3';
export default {
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      //系统教程
      tempId: '',
      action: 'https://gewawe.yangongzi.cn/clientserver.php/Common/attachment',
      imgUrl: '',
      title: '方向管理',
      tableData: [],
      majorList: [],
      direct: [],
      directionproject: [],
      Alltableoptions: [],
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      key_word: '',
      editForm: {
        id: '',
        title: '',
        // 专业名称
        sort: '',
        // 专业排序号
        project_type: '',
        //项目分类
        major_id: '',
        //专业id
        content: '',
        //内容
        remark: '',
        //摘要
        file_id: '' //附件id
      },
      textMap: {
        update: '编辑专业',
        create: '新增专业'
      },
      ruleForm: {},
      dialogStatus: '',
      dialogFormVisible: false,
      InformationBox: false,
      rules: {
        title: [{
          required: true,
          message: '请输入专业名称',
          trigger: 'blur'
        }]
      },
      row: undefined,
      newV: ''
    };
  },
  components: {
    Head: Head,
    Upload: Upload
  },
  created: function created() {
    if (this.course != 1) {
      this.GetList();
    } else {}
  },
  mounted: function mounted() {},
  methods: {
    formatter: function formatter(row, column) {
      return row.title;
    },
    obth: function obth(val) {
      this.editForm.major_id = val;
    },
    //点击上传
    uploadSectionFile: function uploadSectionFile(param) {
      var _this = this;
      var newfile = new FormData();
      newfile.append('file', param.file);
      newfile.append('type', '200008');
      Directionatta(newfile).then(function (res) {
        _this.editForm.file_id = res.data.id;
      });
    },
    GetList: function GetList() {
      var _this2 = this;
      DirectionList({
        pagesize: this.query.pagesize,
        page: this.query.page,
        key_word: this.key_word
      }).then(function (respomse) {
        _this2.tableData = respomse.data.direction_list;
        _this2.query.total = respomse.data.num;
        _this2.majorList = respomse.data.major_list;
        _this2.directionproject = respomse.data.direction_project;
      });
    },
    //查询
    handleFilter: function handleFilter() {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.query.page = 1;
        _this3.GetList();
      });
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this4 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this4.GetList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this5 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this5.GetList();
      });
    },
    //新建按钮
    handleAddClass: function handleAddClass() {
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.editForm = {};
      this.direct = [];
    },
    //新建方向
    createData: function createData() {
      var _this6 = this;
      this.editForm.project_type = this.direct.toString();
      DirectionAdd(this.editForm).then(function (res) {
        _this6.dialogFormVisible = false;
        _this6.$notify({
          message: '提交成功',
          type: 'success'
        });
        _this6.$nextTick(function () {
          _this6.GetList();
        });
      });
    },
    //编辑
    handleupdataClass: function handleupdataClass() {
      if (this.Alltableoptions.length !== 0) {
        if (this.Alltableoptions.length == 1) {
          this.dialogStatus = 'update';
          this.dialogFormVisible = true;
          this.direct = this.editForm.project_type.split(',');
        } else {
          this.$message('只能选择一个进行编辑');
        }
      } else {
        this.$message('请一个进行编辑');
      }
    },
    updateData: function updateData() {
      var _this7 = this;
      this.editForm.project_type = this.direct.toString();
      Directionupdate(this.editForm).then(function (res) {
        _this7.$notify({
          message: '修改成功',
          type: 'success'
        });
        _this7.$nextTick(function () {
          _this7.GetList();
        });
        _this7.dialogFormVisible = false;
      }).catch(function () {
        _this7.dialogFormVisible = false;
      });
    },
    //删除
    handledelClass: function handledelClass() {
      var _this8 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确定删除吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            id: _this8.tempId.toString()
          };
          Directiondel(para).then(function (res) {
            _this8.$notify({
              message: '删除成功',
              type: 'success'
            });
            _this8.$nextTick(function () {
              //获取列表方法
              _this8.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中删除项');
      }
    },
    //选中
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      // this.editForm = Object.assign({}, this.Alltableoptions[0]);
      this.editForm = this.Alltableoptions[0];
      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
      }
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      }
      // this.$refs.editor.setContent();
    },
    // 点击查看详情
    SeeDetailsBtn: function SeeDetailsBtn(row) {
      this.row = row;
      this.ruleForm = Object.assign({}, this.row);
      this.editForm = Object.assign({}, this.row);
      this.InformationBox = true;
    },
    // 详情编辑
    InformationEditor: function InformationEditor() {
      this.InformationBox = false;
      this.dialogFormVisible = true;
      this.direct = this.editForm.project_type.split(',');
    },
    // 详情删除按钮
    InstitutionInformationDeletion: function InstitutionInformationDeletion() {
      var _this9 = this;
      Directiondel({
        id: this.row.id
      }).then(function (res) {
        _this9.$notify({
          message: '删除成功',
          type: 'success'
        });
        _this9.$nextTick(function () {
          //获取列表方法
          _this9.GetList();
        });
        _this9.InformationBox = false;
      });
    }
  }
};