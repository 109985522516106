var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "80px" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { height: "30px", margin: "10px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建"],
                                expression: "['新建']",
                              },
                            ],
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleAddClass },
                          },
                          [_vm._v("新建方向")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["编辑"],
                                expression: "['编辑']",
                              },
                            ],
                            staticClass: "m0",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleupdataClass },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["删除"],
                                expression: "['删除']",
                              },
                            ],
                            staticClass: "m0",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handledelClass },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticStyle: { float: "right" }, attrs: { span: 5 } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              placeholder: "请输入方向名称查询",
                              size: "small",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleFilter($event)
                              },
                            },
                            model: {
                              value: _vm.key_word,
                              callback: function ($$v) {
                                _vm.key_word = $$v
                              },
                              expression: "key_word",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.handleFilter },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "default-sort": { prop: "sort", order: "descending" },
                        data: _vm.tableData,
                        border: "",
                        "tooltip-effect": "light",
                        "show-overflow-tooltip": false,
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                          textAlign: "center",
                        },
                        "cell-style": { textAlign: "center" },
                      },
                      on: { "selection-change": _vm.selsChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "序号", width: "100", algin: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3093164208
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "方向名称", width: "150" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _c(
                                  "span",
                                  {
                                    staticClass: "blue-tit",
                                    on: {
                                      click: function ($event) {
                                        return _vm.SeeDetailsBtn(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(scope.row.title) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          3120099768
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属专业",
                          width: "150",
                          prop: "major_title",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "项目分类", prop: "project_type" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "添加时间", prop: "create_time" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "修改时间", prop: "update_time" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "修改人",
                          width: "100",
                          prop: "updateman",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                _vm._v(" "),
                _c("pagination", {
                  attrs: {
                    "current-page": _vm.query.page,
                    totalPage: _vm.query.total,
                    pageSize: _vm.query.pagesize,
                  },
                  on: {
                    handleSizeChange: _vm.handleSizeChange,
                    handleCurrentChange: _vm.handleCurrentChange,
                  },
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "80%",
                  title: _vm.textMap[_vm.dialogStatus],
                  visible: _vm.dialogFormVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.editForm,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "方向名称", prop: "title" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "280px" },
                          attrs: { placeholder: "请输入专业名称" },
                          model: {
                            value: _vm.editForm.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "title", $$v)
                            },
                            expression: "editForm.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属专业", prop: "major_id" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "请选择" },
                            on: { change: _vm.obth },
                            model: {
                              value: _vm.editForm.major_title,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "major_title", $$v)
                              },
                              expression: "editForm.major_title",
                            },
                          },
                          _vm._l(_vm.majorList, function (v, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { label: v, value: i },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "项目分类", prop: "direct" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.direct,
                              callback: function ($$v) {
                                _vm.direct = $$v
                              },
                              expression: "direct",
                            },
                          },
                          _vm._l(_vm.directionproject, function (item) {
                            return _c(
                              "el-checkbox",
                              {
                                key: item,
                                attrs: { label: item, value: item },
                                on: { change: _vm.obth },
                              },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方向简介", prop: "content" } },
                      [
                        _c("quill-editor", {
                          ref: "myTextEditor",
                          attrs: { options: _vm.editorOption },
                          model: {
                            value: _vm.editForm.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "content", $$v)
                            },
                            expression: "editForm.content",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "摘要", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入内容",
                          },
                          model: {
                            value: _vm.editForm.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "remark", $$v)
                            },
                            expression: "editForm.remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "附件", prop: "file_id" } },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              "http-request": _vm.uploadSectionFile,
                              "show-file-list": true,
                              action: "action",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("点击上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible = false
                          },
                        },
                      },
                      [_vm._v("\n                    取消\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogStatus === "create"
                              ? _vm.createData()
                              : _vm.updateData()
                          },
                        },
                      },
                      [_vm._v("\n                    确认\n                ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialog",
                attrs: { title: "方向详情", visible: _vm.InformationBox },
                on: {
                  "update:visible": function ($event) {
                    _vm.InformationBox = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "header-title",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("span", { staticClass: "title-name" }, [
                      _vm._v("方向详情"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "headerBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.InformationEditor()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        编辑\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.InstitutionInformationDeletion()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        删除\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "table",
                    {
                      staticClass: "tabstyles",
                      attrs: { border: "1", rules: "all" },
                    },
                    [
                      _c("tr", [
                        _c("th", [_vm._v("方向名称:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.title))]),
                        _vm._v(" "),
                        _c("th", [_vm._v("所属专业:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.major_title))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("项目分类:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.project_type))]),
                        _vm._v(" "),
                        _c("th", [_vm._v("附件:")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "blueFont",
                            on: {
                              click: function ($event) {
                                return _vm.downloadBtn(_vm.ruleForm.file)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.ruleForm.file) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "concentbox" }, [
                        _c("th", [_vm._v("方向简介:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", {
                            staticClass: "isimg",
                            domProps: {
                              innerHTML: _vm._s(_vm.ruleForm.content),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("摘要:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "isimg" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.ruleForm.remark) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("创建人:")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm.ruleForm.publisher != ""
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.publisher)),
                              ])
                            : _c("span", [_vm._v("--")]),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("更新人:")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm.ruleForm.updateman != ""
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.updateman)),
                              ])
                            : _c("span", [_vm._v("--")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("创建时间:")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm.ruleForm.addtime == "0"
                            ? _c("span", [_vm._v("---")])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.create_time)),
                              ]),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("更新时间:")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm.ruleForm.updatetime == "0"
                            ? _c("span", [_vm._v("---")])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.update_time)),
                              ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }